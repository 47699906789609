import React, { useContext, useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import { Formik, Form } from "formik"
import classNames from "classnames"
import Card from "elements/Card"
import Button from "elements/Button"
import Message from "elements/Message"

import { authValidationSchema } from "../../Auth/utils/authValidationSchema"
import { handleAuthSubmit } from "../../Auth/services/auth"
import { AppContext } from "../../../context/AppContext"
import AuthForm from "../../Auth/AuthForm"
import { hasSignedInUser } from "../../Auth/services/user"

const LoginCard = ({ pageContext, location }) => {
  const [message, setMessage] = useState({})
  const [loading, setLoading] = useState(false)

  const { state, dispatch } = useContext(AppContext)

  useEffect(() => {
    let userData = JSON.parse(sessionStorage.getItem("userData"));
    if (hasSignedInUser()){
      if(userData.domain.validity === false || userData.domain.validity === "inactive"){
        navigate("/access-code")
      }else {
        navigate("/services")}
    } 
  }, [])

  const handleSubmit = (values, { resetForm }) => {
    setMessage({})
    setLoading(true)

    let payload = {
      values,
      resetForm,
      setMessage,
      setLoading,
      module: "sign-in",
    }

    handleAuthSubmit({ payload, state, dispatch, location: location })
  }

  return (
    <Card className="is-hidden-mobile is-hidden-tablet-only">
      <Formik
        initialValues={state.auth}
        validationSchema={authValidationSchema("sign-in")}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form>
            <AuthForm module={"sign-in"} formFields={pageContext.formFields} />
            {message?.content && (
              <Message color={message?.type}>
                {message?.content?.message || message?.content}
              </Message>
            )}
            <h5 className="has-text-primary has-text-weight-bold mt-0 mb-2">
              <Link to={`/forgot-password`}>Forgot password?</Link>
            </h5>

            <Button
              type="submit"
              color="secondary"
              // disabled={loading}
              className={classNames({ "is-loading": loading })}
              isFullwidth
            >
              {module.cta || "Sign In"}
            </Button>
            <section>
              <h5 className="has-text-centered mt-2">
                Don't have an account?{" "}
                <Link
                  className="has-text-secondary has-text-weight-bold"
                  to="/sign-up"
                >
                  Create new account
                </Link>
              </h5>
            </section>
          </Form>
        )}
      </Formik>
    </Card>
  )
}

export default LoginCard
