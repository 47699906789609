import React, { useState } from "react"
import { navigate } from "gatsby"

import Layout from "layout"
import Container from "layout/Container"
import Hero from "layout/Hero"
import Hexagon from "../../Layout/Hexagon"
import Video from "elements/Video"
import Button from "../../Elements/Button"

import ProgramDescriptions from "./ProgramDescriptions"
import LoginCard from "./LoginCard"
import HowItWorks from "../../Elements/HowItWorks"

import useHomeImages from "../hooks/useHomeImages"
import howProgramWorks from "../utils/howProgramWorks.json"

import styles from "../utils/staticPages.module.scss"
import phThumbnail from "../../../../static/images/ph_thumbnail.png"
import mhThumbnail from "../../../../static/images/mh_thumbnail.png"

import {
  GATSBY_PHYSICAL_HEALTH_VIDEO,
  GATSBY_MENTAL_HEALTH_VIDEO,
} from "gatsby-env-variables"
import classNames from "classnames"

const Home = ({ pageContext, location }) => {
  const [loading, setLoading] = useState(false)
  const handleNavigate = () => {
    setLoading(true)
    navigate("/sign-in")
  }

  return (
    <Layout
      display={{ helpCenterBanner: false, footer: true }}
      seoTitle="Teleconsult"
      pageContext={pageContext}
      polygonStyle="landingPage"
    >
      <Hero className="is-relative">
        <Container isCentered desktop={10} fullhd={8}>
          <div className="pt-6 pb-6">
            <div className="columns is-desktop">
              <div className="column is-one-half is-flex is-justify-content-center is-flex-direction-column mx-1-mobile">
                <h1 className="mt-1 mb-0 has-text-primary no-wrap">
                  Delivering healthcare
                </h1>
                <h1 className="mt-0 mb-1 has-text-primary">everywhere</h1>
                <h3 className={`mb-1 has-text-weight-normal`}>
                  Choose a schedule that works for you. Teleconsults are{" "}
                  <strong className="has-text-secondary">
                    free for employees
                  </strong>{" "}
                  of our corporate partners.
                </h3>
              </div>
              <div className="column mx-1-mobile">
                <Button
                  onClick={handleNavigate}
                  isLoading={loading}
                  color="secondary"
                  className={classNames(
                    "has-text-weight-bold is-hidden-desktop",
                    styles["button_get_started"]
                  )}
                >
                  Get Started
                </Button>
                <div className="is-flex is-flex-wrap-wrap	is-justify-content-center mb-2">
                  <LoginCard pageContext={pageContext} location={location} />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Hero>
      <ProgramDescriptions />
      <HowItWorks howProgramWorks={howProgramWorks} useImages={useHomeImages} />

      <div className="is-relative pb-1-mobile pb-7">
        <h2 className="has-text-centered has-text-primary mt-6 mt-3-mobile mb-1-mobile mb-2 py-2 px-2 px-4-mobile line-height-1">
          Learn how our programs <span>can help you</span>
        </h2>
        <Hexagon>
          {/* {GATSBY_PHYSICAL_HEALTH_VIDEO ? (
            <div className="has-text-centered pb-3-mobile py-6 py-1-mobile">
              <h2 className="has-text-primary mb-2">Physical Health</h2>
              <Video
                videoSrcURL={GATSBY_PHYSICAL_HEALTH_VIDEO}
                videoTitle="Teleconsult Service"
                thumbnail={phThumbnail}
              />
            </div>
          ) : null}
          {GATSBY_MENTAL_HEALTH_VIDEO ? (
            <div className="has-text-centered pb-3-mobile py-6 py-1-mobile">
              <h2 className="has-text-primary mb-2">Mental Health</h2>
              <Video
                videoSrcURL={GATSBY_MENTAL_HEALTH_VIDEO}
                videoTitle="Teleconsult Service"
                thumbnail={mhThumbnail}
              />
            </div>
          ) : null} */}
        </Hexagon>
      </div>
    </Layout>
  )
}

export default Home
