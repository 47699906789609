import React from "react"

import Container from "layout/Container"
import Card from "elements/Card"
import mhHead from "../../../../static/images/icons/mh_head.png"
import mhTelescope from "../../../../static/images/icons/mh_telescope.png"
import styles from "../utils/staticPages.module.scss"

const ProgramDescriptions = () => {
  return (
    <Container isCentered desktop={10} fullhd={8}>
      <div className="pt-6-mobile pb-6 pb-0-mobile mt-3 mb-2 mb-0-mobile">
        <h2 className="has-text-centered has-text-primary mb-1-mobile has-background-white py-1 line-height-1 mb-1 px-3-mobile mx-3 mx-0-mobile">
          Discover the Right Care <span>Option for You</span>
        </h2>
        <Card className="card--mobile">
          <div className="columns py-1 py-0-mobile">
            <div className="column one-half px-2 px-1-mobile is-relative mini-card mb-2-mobile is-flex is-flex-direction-row">
              {/* HEADER */}
              <div className="column">
                <h2 className="pb-1 is-inline-block has-text-weight-bold	pt-0-mobile has-border-bottom-secondary">
                  Mental Health
                </h2>
                <p className="mt-1 pr-1-mobile pr-2-tablet">
                  Engage with licensed mental health professionals on a 24/7
                  appointment-based schedule.
                </p>
              </div>
              <img
                src={mhHead}
                alt="mh-head-icon"
                className={styles["mh_icon"]}
              />
            </div>
            <div className="column one-half px-2 px-1-mobile is-relative pb-1-mobile mini-card is-flex is-flex-direction-row">
              {/* HEADER */}
              <div className="column">
                <h2 className="has-text-weight-bold pb-1 is-inline-block has-border-bottom-secondary">
                  Physical Health
                </h2>
                <p className="mt-1 pr-1-mobile pr-2-tablet">
                  Book a teleconsult with licensed doctors for a general check
                  up, prescription renewal, or return to office clearance.
                </p>
              </div>
              <img
                src={mhTelescope}
                alt="mh-telescope-icon"
                className={styles["mh_icon"]}
              />
            </div>
          </div>
        </Card>
      </div>
    </Container>
  )
}

export default ProgramDescriptions
